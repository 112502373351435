<template>
    <v-container class="intake-form-page" v-if="hasAccessToPage">
        <v-row justify="center">
            <v-col cols="12" lg="12" xl="8">
                <v-card class="elevation-1 bg-whitesmoke card-empty visitor-card">
                    <v-list-item one-line class="pt-1">
                        <v-list-item-content>
                            <v-list-item-title class="subtitle-1 mb-1 primary--text font-weight-medium">
                                HOH ENTRY FORM CALENDAR
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>   
                        <v-card-text class="bg-white text-area pb-8" style="background:#f9f9f9 !important">
                            <HohEntryFormCalendar />
                        </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <NoAccessMessage v-else />
</template>

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue'
import NoAccessMessage from '@/components/generic/no-access-message.vue'
//import PrivateEntryForm from '@/views/HohEntryFormPage/components/HohEntryForm.vue'
import HohEntryFormCalendar from '@/views/HohEntryFormPage/components/HohEntryFormCalendar.vue'

export default {
    mixins: [SharedFunctions],
    components: {
        NoAccessMessage,
        HohEntryFormCalendar
    },
    data() {
        return {
            hasAccessToPage: false,
        }
    },
    created() {
        this.hasAccessToPage = this.hasAccessToPageMixin();
    }

}
</script>

<style>

</style>