<template>
  <v-row class="fill-height">
    <v-col>
      <v-sheet height="64">
        <v-toolbar flat color="white">
          <v-btn outlined class="mr-4" color="grey darken-2" @click="setToday()">
            Today
          </v-btn>
          <v-btn fab class="mx-2" small color="grey lighten-2" elevation="0"  @click="prev()">
            <v-icon small>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn class="mx-2" fab small color="grey lighten-2" elevation="0" @click="next()">
            <v-icon small>mdi-chevron-right</v-icon>
          </v-btn>

          <v-toolbar-title class="ms-2">
            {{ currentMonth }}
          </v-toolbar-title>

        </v-toolbar>
      </v-sheet>
      <v-sheet height="600" class="calendar-wrapper">
        <div class="loader-wrapper" v-if="loading">
            <v-progress-circular
                indeterminate
                color="primary">
            </v-progress-circular>
        </div>
        <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :type="type"
          :events="events"
          :show-month-on-first="false"
          @click:date="dateClick($event)"
          @click:day="dateClick($event)"
          @change="calendarChanged($event)"
        ></v-calendar>
      </v-sheet>
    </v-col>
  </v-row>
</template>

<script>
    import moment from 'moment';

    export default {
        data: () => ({
            loading: false,
            currentMonth: null,
            focus: '',
            type: 'month',
            typeToLabel: {
                month: 'Month',
                week: 'Week',
                day: 'Day',
                '4day': '4 Days',
            },
            events: [],
        }),
        mounted () {

        },
        methods: {
            dateClick(event) {
                this.$router.push({name: 'HohEntryFormEntriesPage', params: {selectedDate: event.date} });
            },
            setToday() {
                this.focus = ''
            },
            next() {
                this.$refs.calendar.next()
            },
            prev() {
                this.$refs.calendar.prev()
            },
            calendarChanged(event) {
                this.currentMonth = moment(event.start.month, 'MM').format('MMMM');
                this.loading = true;
                this.$store.dispatch('getHohEntryFormsAppointments', {minDate: event.start.date, maxDate: event.end.date}).then((data) => {
                    this.loading = false;
                    let events = data.map(event => {
                        return {
                            name: event.firstNamePatient,
                            start: moment.utc(event.dateTimeAppointment, 'YYYY-MM-DDThh:mm').local().format('YYYY-MM-DD HH:mm')
                        }
                    });
                    this.events = events;
                });
            }

        },
    }
</script>

<style lang="scss">
.calendar-wrapper {
    position: relative;
    .loader-wrapper {
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgba(white,.7);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .v-calendar-weekly__day:hover{
      cursor: pointer;
      background: whitesmoke;
    }
    .v-calendar-weekly__day.v-outside {
      pointer-events: none;
    }
}

</style>